import * as _react2 from "react";

var _react = "default" in _react2 ? _react2.default : _react2;

var exports = {};
var e = _react;

function h(a, b) {
  return a === b && (0 !== a || 1 / a === 1 / b) || a !== a && b !== b;
}

var k = "function" === typeof Object.is ? Object.is : h,
    l = e.useState,
    m = e.useEffect,
    n = e.useLayoutEffect,
    p = e.useDebugValue;

function q(a, b) {
  var d = b(),
      f = l({
    inst: {
      value: d,
      getSnapshot: b
    }
  }),
      c = f[0].inst,
      g = f[1];
  n(function () {
    c.value = d;
    c.getSnapshot = b;
    r(c) && g({
      inst: c
    });
  }, [a, d, b]);
  m(function () {
    r(c) && g({
      inst: c
    });
    return a(function () {
      r(c) && g({
        inst: c
      });
    });
  }, [a]);
  p(d);
  return d;
}

function r(a) {
  var b = a.getSnapshot;
  a = a.value;

  try {
    var d = b();
    return !k(a, d);
  } catch (f) {
    return !0;
  }
}

function t(a, b) {
  return b();
}

var u = "undefined" === typeof window || "undefined" === typeof window.document || "undefined" === typeof window.document.createElement ? t : q;
exports.useSyncExternalStore = void 0 !== e.useSyncExternalStore ? e.useSyncExternalStore : u;
export default exports;